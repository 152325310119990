<template>
  <div>
    <!--begin::User-->
    <div>

      <div class="pb-5 d-flex justify-content-between">
        <h3 class="card-label">
          {{ $t('MENU.periods') }}
          <span class="text-muted pt-2 font-size-sm d-block"></span>
        </h3>
      </div>
      <div class="row mt-5">
        <div class="col-md-12">

          <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
            <h6 class="mt-2">{{ $t('periods.periods') }}</h6>
              <button type="button" class="btn btn-primary mr-2" @click="addToRepeater">{{ $t('add_more') }}</button>
          </div>
          <div class="bg-white">
            <table class="table" @keyup.enter="addToRepeater" @keyup.46="deleteFromRepeater(0)">
              <thead>
              <tr class="text-center">
                <th>{{ $t('periods.number') }}</th>
                <th>{{ $t('periods.period') }}</th>
                <th>{{ $t('periods.is_active') }}</th>
                <th class="text-center"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(cu, index) in periods" :key="index">
                <td>
                  <input type="number" v-model="cu.number" class="form-control" :placeholder="$t('periods.number')"/>
                  <span v-if="validation && validation['periods.'+index+'.number']" class="fv-plugins-message-container invalid-feedback">
                                         {{ validation['periods.' + index + '.number'][0] }}
                                        </span>
                </td>
                <td>
                  <input type="number" v-model="cu.period" class="form-control" :placeholder="$t('periods.period')"/>
                  <span v-if="validation && validation['periods.'+index+'.period']" class="fv-plugins-message-container invalid-feedback">
                                         {{ validation['periods.' + index + '.period'][0] }}
                                        </span>
                </td>

                <td class="text-center">
                  <b-form-checkbox size="lg" @change="changeStatus(cu.id, cu.is_active)" v-model="cu.is_active" :name="'check-button'+cu.id" switch :key="cu.id"></b-form-checkbox>
                </td>

                <td class="text-center">
                  <v-icon class="text-danger" @click="cu.id ? deleteItem(cu) : deleteFromRepeater(index)">mdi-delete</v-icon>

                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>


      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>
    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Vue from "vue";

export default {
  name: "from-periods",
  data() {
    return {
      mainRoute: 'settings/periods',
      subMainRoute: 'settings/period',
      mainRouteDependency: 'base/dependency',
      customers: [],

      dataList: [],
      isEditing: false,
      validation: null,
      repeater_periods: {id: null, number: null, period: null, is_active: false},
      periods: [],
    };
  },

  methods: {


    save() {

      ApiService.post(this.mainRoute, {
        periods: this.periods,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.getData();
      }).catch((error) => {
        Vue.prototype.$postStatus = true;
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    //
    // update() {
    //
    //     ApiService.put(this.mainRoute + '/' + this.idEdit, {
    //         customers: this.data_customers,
    //     }).then((response) => {
    //         this.validation = null;
    //         this.$successAlert(response.data.message);
    //     }).catch((error) => {
    //         this.validation = error.response ? error.response.data.errors : null;
    //     });
    // },

    async getData() {
      await ApiService.get(this.mainRoute).then((response) => {
        this.isEditing = true;
        this.periods = response.data.data;
        this.dataList = response.data.data;
        if (response.data.data.length <= 0) {
          this.addToRepeater();
        }
      });
    },


    addToRepeater() {
      this.periods.unshift(this.repeater_periods);
      this.repeater_periods = {id: null, number: null, period: null, is_active: false};
    },
    deleteFromRepeater(index) {
      this.periods.splice(index, 1);
    },

    actionDelete(item) {
      ApiService.delete(this.mainRoute + "/" + item.id).then((response) => {
        this.getData();
        this.$successAlert(response.data.message)
      }).catch((error) => {
        Vue.prototype.$postStatus = true;
        this.$errorAlert(error);
      })
    },
    deleteItem(item) {
      this.$confirmAlert('', this.actionDelete, item);
    },
    changeStatus(id, status) {
      if (id) {
        ApiService.patch(this.subMainRoute + '/change-status/' + id, {
          is_active: (status ? '1' : '0'),
        }).then(response => {
          this.$successAlert(response.data.message);
        }).catch(error => {
          Vue.prototype.$postStatus = true;
          this.$errorAlert(error);
        });
      }

    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.periods")}]);

    let promise = this.getData();
    let that = this;
    Promise.all([promise]).then(() => {
      if (that.periods.length <= 0) {
        that.addToRepeater();
      }
    });


  },
};
</script>


